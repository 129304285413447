import Link from "next/link";
import { memo, useEffect, useState } from "react";

const HorizontalTab = ({ data, title, activeSlide }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [hoverState, setHoverState] = useState("");
  useEffect(() => {
    if (activeSlide) {
      setActiveTab(activeSlide);
    }
  }, [activeSlide]);
  return (
    <div className="bg-primary-black px-4 lg:pt-20 md:px-10">
      <div className="text-center text-4xl lg:text-6xl mb-7 webkit-text-stroke-white">
        <h2 className="text-primary-black font-poppins px-3 md:px-0">
          <span className="font-poppins">{title}</span>
        </h2>
      </div>
      <ul className="flex justify-start md:justify-center w-full overflow-auto ps-0 hide-scrollbar">
        {data.map((itm, index) => (
          <li
            key={itm.title}
            onClick={() => setActiveTab(index)}
            className={`list-none cursor-pointer py-3 mx-5 whitespace-nowrap text-xl first:ml-0 border-2 border-solid border-t-0 border-x-0 text-white ${
              activeTab === index
                ? "border-white text-opacity-100"
                : "border-transparent text-opacity-40"
            } `}
          >
            <span>{itm.title}</span>
          </li>
        ))}
      </ul>
      <div className="flex flex-wrap justify-center pb-4 pt-12">
        <div className="flex justify-center mx-auto w-[94%]">
          <div className="flex md:justify-center xl:justify-between flex-wrap lg:gap-8">
            {data[activeTab]?.techsname.map((ele) => (
              <div
                key={ele.name}
                className="w-1/2 md:w-2/12 lg:w-1/12 h-auto md:h-52 md:mx-4 md:flex md:justify-center md:items-center lg:inline"
              >
                {hoverState === ele.name ? (
                  <Link href={ele.link}>
                    <a href={ele.link} onMouseLeave={() => setHoverState("")}>
                      <div className="flex flex-col justify-center items-center">
                        <div className="rounded-full flex justify-center items-center w-16 h-16 md:w-28 md:h-28 peer hover:animate-pulse bg-[#2c2424] fs-0">
                          <span> {ele.hoverImage}</span>
                        </div>
                        <p className="text-white text-center pt-2 xl:pt-4 mb-2 md:mb-4 text-base md:text-xl opacity-0 peer-hover:opacity-100">
                          <span>{ele.name}</span>
                        </p>
                      </div>
                    </a>
                  </Link>
                ) : (
                  <div
                    className="flex justify-center items-center flex-col"
                    onMouseEnter={() => setHoverState(ele.name)}
                  >
                    <div className="rounded-full flex justify-center items-center w-16 h-16 md:w-28 md:h-28 peer hover:animate-pulse fs-0">
                      <span>{ele.image}</span>
                    </div>
                    <p className="text-white text-center pt-2 xl:pt-4 mb-2 md:mb-4 text-base md:text-xl opacity-0 peer-hover:opacity-100">
                      <span>{ele.name}</span>
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HorizontalTab);
