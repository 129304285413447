const CounterComponent = ({
  data,
  mainContainerClass,
  secondMainContainerClass,
}) => (
  <div>
    <div className={`flex flex-wrap w-full ${mainContainerClass}`}>
      {data.map((value) => (
        <div
          key={value.id}
          className={`w-1/2 flex flex-col py-4 md:py-0 ${secondMainContainerClass}`}
        >
          <div className="text-start h-full">
            <div className="h-16 md:h-24">
              <span className="font-bold text-transparent font-poppins text-5xl md:text-7xl webkit-text-stroke-white inline-block">
                {value.end}
              </span>
              <span className="font-bold text-transparent font-poppins text-5xl md:text-7xl webkit-text-stroke-white inline-block">
                {value.sign}
              </span>
            </div>
            <p className="text-primary-yellow mb-8 text-lg w-3/4 md:w-full -mt-2 md:mt-0 xl:text-xl text-balance lg:text-nowrap">
              <span>{value.title}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default CounterComponent;
